import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "../components/navbar.css";
import api from "../apiConfig/apiConfig"; // Import the Axios instance
import { Mail, Phone } from "lucide-react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    comment: "",
  });

  const [message, setMessage] = useState(null); // To display success/error messages

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/contact", formData); // Sending data to backend
      setMessage({ type: "success", text: response.data.message });
      setFormData({ name: "", email: "", contactNumber: "", comment: "" }); // Reset form after successful submission
    } catch (error) {
      setMessage({ type: "error", text: "Something went wrong. Please try again." });
      console.error("Error submitting contact form:", error);
    }
  };

  return (
    <div className="flex flex-col bg-[#D9D9D9] h-screen">
      <div className="bg-white">
        <Navbar />
      </div>

      <div className="mt-[5rem]">
        <div className="bg-white shadow-md rounded-lg grid grid-cols-2 gap-[2rem] p-8 max-w-5xl w-full mx-auto">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold">We are here to help!</h2>
            <p className="mt-4 text-[#604720] text-md">
              Let us know how we can best serve you. Use the contact form to email us. It’s an honor to support you in your journey towards better education.
            </p>
            <div className="mt-6 space-y-4">
  {/* Email */}
  <div className="flex items-center space-x-3">
    <Mail className="h-6 w-6 text-[#604720]" />
    <span className="text-md text-[#604720]">dreampost.in@gmail.com</span>
  </div>

  {/* Phone */}
  <div className="flex items-center space-x-3">
    <Phone className="h-6 w-6 text-[#604720]" />
    <span className="text-md text-[#604720]">+919131778461</span>
  </div>
</div>
          </div>

          <form className="mt-6 space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
            <input
              type="text"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              placeholder="Contact number"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
            <textarea
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              placeholder="Comment"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
            <button
              type="submit"
              className="bg-[#604720] text-white font-bold py-2 px-4 rounded hover:bg-[#4e3711] transition duration-200"
            >
              SEND MESSAGE
            </button>
          </form>
        </div>

        {message && (
          <div
            className={`mt-4 text-center font-semibold ${
              message.type === "success" ? "text-green-600" : "text-red-600"
            }`}
          >
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
