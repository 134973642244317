import React, { useEffect, useState } from "react";
import User from "../assets/hero.png";
import background from "../assets/bg.png";
import { useNavigate } from "react-router-dom";
import progress from "../assets/progress.png";
import analytics from "../assets/analytics.png";
import schedule from "../assets/schedule.png";
import addToHomeImage from "../assets/hero.jpg"; // Replace with your image

export default function Hero() {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setAnimate(true);
  
    // Check if the user has seen the modal before
    const hasSeenModal = localStorage.getItem("addToHomeScreenShown");
    if (!hasSeenModal) {
      setShowModal(true);
      localStorage.setItem("addToHomeScreenShown", "true");
    }
  }, []);
  

  const handleForm = () => {
    navigate("/form");
  };

  return (
    <div
      className="flex items-center justify-center h-screen inset-0 -z-10"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: "-10",
      }}
    >
      <div
        className={`container mx-auto flex flex-col md:flex-row items-center justify-between md:w-[75%] px-5 md:px-0 transform transition-all duration-1000 ease-in-out ${
          animate ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
        }`}
      >
        {/* Text Section with Icons */}
        <div className="hero-content text-center md:text-left space-y-10 text-white mb-10 md:mb-0 hero-text">
          <ul className="space-y-4 list-none pl-0">
            <li className="flex gap-4 text-3xl font-normal items-center">
              <img src={analytics} alt="Assess" className="w-10 h-10 mr-3" />
              Assess yourself
            </li>
            <li className="flex text-3xl gap-4 font-normal items-center">
              <img src={schedule} alt="Schedule" className="w-10 h-10 mr-3" />
              Pick your schedule
            </li>
            <li className="flex text-3xl gap-4 font-normal items-center">
              <img src={progress} alt="Progress" className="w-10 h-10 mr-3" />
              Progress with us
            </li>
          </ul>
          <button
            onClick={handleForm}
            className="bg-[#1A6400] border-[2.5px] border-[white] text-white py-2 px-12 rounded-lg transition-transform transform hover:translate-y-1 hover:scale-105 duration-300 ease-in-out"
          >
            GET CUSTOMIZED SCHEDULE
          </button>
        </div>
      </div>

      {/* Add to Home Screen Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[450px] relative text-center">
            {/* Close Button */}
            <button
              className="absolute top-3 right-4 text-gray text "
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>

            <h2 className="text-2xl font-bold mb-4 text-[#0011AC]">Add to Home Screen</h2>
            <img
              src={addToHomeImage}
              alt="Add to Home"
              className="w-450px mx-auto mb-4"
            />
<p className="text-md text-[#1A226C]">Install this app on your phone!</p>
<p className="text-md mt-2 text-[#1A226C]">
  Tap the three dots and select <b>'Add to Home Screen'</b>.
</p>

          </div>
        </div>
      )}
    </div>
  );
}