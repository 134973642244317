import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import leave from "../assets/leave.png";
import LeaveForm from "./LeaveForm";
import api from "../apiConfig/apiConfig";
import whatsappImage from "../assets/watsapp.png"; // Adjust the path based on your folder structure

const Schedule = () => {
  const { goalId } = useParams();
  const navigate = useNavigate();
  const [scheduleData, setScheduleData] = useState(null);
  const [currentDay, setCurrentDay] = useState(1);
  const [selectedDay, setSelectedDay] = useState(1); // Track the day selected by user
  const [showLeaveForm, setShowLeaveForm] = useState(false);
  const [remainingLeaves, setRemainingLeaves] = useState(0);
  const [leaveInfo, setLeaveInfo] = useState(null);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        const token = localStorage.getItem("authToken");

        const response = await api.get(`/course-content/${goalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
console.log(response.data)
        if (response.data.showPrivacyPolicy) {
          navigate("/privacy-policy", { replace: true, state: { goalId: goalId } });
          return;
        }
       if (response.data.message === "You are currently on leave.") {
          setLeaveInfo(response.data);
        } else {
          setScheduleData(response.data);
          console.log(response.data)
          setRemainingLeaves(response.data.remainingLeaves);

          // Calculate the current day based on purchase date
          // const purchasedDate = new Date(response.data.purchasedAt);
          // const today = new Date();
          // const daysElapsed =
          //   Math.floor((today - purchasedDate) / (1000 * 60 * 60 * 24)) + 1;
          const purchasedDate = new Date(response.data.purchasedAt);
purchasedDate.setUTCHours(0, 0, 0, 0);

const today = new Date();
today.setUTCHours(0, 0, 0, 0);

const daysElapsed =
    Math.floor((today - purchasedDate) / (1000 * 60 * 60 * 24)) + 1;


          setCurrentDay(daysElapsed);
          setSelectedDay(daysElapsed); // Default to today's content
        }
      } catch (error) {
        console.error("Error fetching schedule data:", error.message);
      }
    };

    fetchScheduleData();
  }, [goalId]);

  const handleLeaveSubmitted = (updatedLeaves) => {
    setRemainingLeaves(updatedLeaves);
  };

  if (showPrivacyPolicy) {
    navigate("/privacy-policy", { state: { goalId: goalId } });
  }

  if (leaveInfo) {
    return (
      <div className="min-h-screen px-[4rem] flex flex-col items-center justify-center">
        <h1 className="text-2xl font-bold text-center">{leaveInfo.message}</h1>
        <p className="text-center text-gray-600 mt-2">
          Your leave period:{" "}
          <strong>
            {new Date(leaveInfo.leaveStart).toLocaleDateString()} -{" "}
            {new Date(leaveInfo.leaveEnd).toLocaleDateString()}
          </strong>
        </p>
        <p className="text-center text-gray-600 mt-2">{leaveInfo.note}</p>
      </div>
    );
  }

  if (!scheduleData) {
    return <div>Loading...</div>;
  }

  const { totalDays } = scheduleData;

  // Generate past 3 days + today
  // const generateDaysArray = () => {
  //   const days = [];
  //   const startDay = Math.max(currentDay - 3, 1); // Up to 3 past days
  //   const endDay = Math.min(startDay + 4, totalDays); // Ensure at most 5 days are displayed
  
  //   for (let i = startDay; i <= endDay; i++) {
  //     days.push(i);
  //   }
  
  //   return days;
  // };
  const generateDaysArray = () => {
    const days = [];
    const startDay = Math.max(currentDay - 3, 1); // Show past 3 days (or from day 1)
    
    for (let i = startDay; i <= currentDay; i++) { // Only up to today
      days.push(i);
    }
  
    return days;
  };
  const daysArray = generateDaysArray();
  

  return (
    <div className="min-h-screen px-[4rem]">
      <div className="flex items-center justify-between mt-12 mb-12">
        {/* Left: Heading */}
        <div className="flex flex-col items-center text-center">
          <h1 className="text-2xl  baloo-font">{scheduleData.exam} {scheduleData.stage}</h1>
          <p className="text-[#1A6400] baloo-font">
            ({scheduleData.remainingDays} more days to go)
          </p>
        </div>

        {/* Center: Days Selection */}
        <div className="flex justify-center ml-[-135px]">

        <div className="flex space-x-4">
  {daysArray.map((day, index) => {
    const isFutureDay = day > currentDay; // Identify future days
    return (
      <div
        key={index}
        onClick={() => !isFutureDay && setSelectedDay(day)}
        className={`w-10 h-10 flex items-center justify-center rounded-full cursor-pointer
          ${selectedDay === day ? "bg-[#1A6400] text-white" : "bg-gray-200 text-gray-800"}
          ${isFutureDay ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-300"}
        `}
      >
        {currentDay === day ? <div className="text-xs">Today</div> : day}
      </div>
    );
  })}
</div>
</div>


        {/* Right: Leave Button */}
        <div className="schedule">
          <img onClick={() => setShowLeaveForm(true)} src={leave} alt="Leave" />
          {showLeaveForm && (
            <LeaveForm
              onClose={() => setShowLeaveForm(false)}
              goalId={goalId}
              maxLeaves={scheduleData.maxLeaves}
              remainingLeaves={remainingLeaves}
              remainingDays={scheduleData.remainingDays}
              onLeaveSubmitted={handleLeaveSubmitted}
            />
          )}
        </div>
      </div>

      <div className="form grid grid-cols-1 lg:grid-cols-2 gap-8 px-20">
        {scheduleData.subjects.map((subject, index) => {
          const selectedContent = subject.dailyContents.find(
            (content) => content.day === selectedDay
          );
          if (!selectedContent || !selectedContent.topics.length) {
            return null; // Skip rendering if no topics are available
          }
          const totalHours =
            selectedContent?.topics.reduce((sum, topic) => sum + topic.hours, 0) || 0;

          const colors = ["#364374", "#1A6400", "#743637", "#00000099"];
          const colorIndex = index % colors.length;
          const bgColor = colors[colorIndex];

          return (
            <div
              key={index}
              className="p-0 border rounded-lg"
              style={{ borderColor: bgColor }}
            >
              <div
                className={`flex justify-between rounded-lg items-center heading text-lg text-white font-bold mb-4 p-4`}
                style={{ backgroundColor: bgColor }}
              >
                <span className="ml-6">{subject.title}</span>
                <span className="text-sm text-white mr-6">Total Hours: {totalHours}</span>
              </div>
              {selectedContent?.topics.map((topic, idx) => (
                <div key={idx} className="mb-4 p-3 flex justify-between items-center ml-6">
                  <div className="flex-1 pr-8">
                    <p className="text-[16px] mb-1 font-bold">{topic.name}</p>
                    {topic.link && topic.link.split(",").map((link, index) => (
  <a
    key={index}
    href={link.trim()} // Trim to remove unnecessary spaces
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-500 underline block" // Use block to display each link on a new line
  >
    View Resource {index + 1}
  </a>
))}

                                        <p className="text-sm mb-1 text-gray-600">{topic.pdfName}</p>
                                        <p className="text-m mb-1 text-black-600">{topic.description}</p>
                  </div>
                  <div className="text-gray-700 font-medium mr-6 pl-8">{topic.hours} hrs</div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center mt-10 mb-6">
      <p className="text-gray-700 text-sm font-medium mr-2">
      <span className="text-red-600 font-bold">Broken link?</span> Report with Schedule name, Subject, and Day on WhatsApp. We'll fix it in 5 mins.
      </p>
      <a
        href="https://wa.me/919131778461"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center"
      >
        <img src={whatsappImage} alt="WhatsApp" className="w-10 h-10 ml-2" />
      </a>
    </div>
    </div>
  );
};

export default Schedule;
