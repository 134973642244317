import { useState,useEffect } from "react";
import "../components/navbar.css";
import { useLocation ,useNavigate} from "react-router-dom";
import api from "../apiConfig/apiConfig";
import Select from 'react-select';

const PersonalInfoForm = () => {
  const location = useLocation();
  const navigate = useNavigate(); // For navigation to the previous page
  const selectedOption = location.state?.selectedOption || "No option selected";
  const profileData = location.state?.profileData || {}; // Access the passed data
  const goalToSend = profileData && profileData.selectedGoal ? profileData.selectedGoal : selectedOption;

console.log(goalToSend,"send")
  const [formData, setFormData] = useState({
    fullName: "",
    employeeNo: "",
    mobileNumber: "",
    email: "",
    category: "",
    post: "",
    currentPosting: "",
    desiredPosting: [], // Initialize as an empty array for multiple options
    proofFile: null,
    additionalDetail: "",
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const options = [
    { value: 'MADHYA PRADESH', label: 'MADHYA PRADESH' },
    { value: 'UTTAR PRADESH', label: 'UTTAR PRADESH' },
    { value: 'RAJASTHAN', label: 'RAJASTHAN' },
    { value: 'GUJARAT', label: 'GUJARAT' },
    { value: 'UTTARAKHAND', label: 'UTTARAKHAND' },
    { value: 'MAHARASHTRA', label: 'MAHARASHTRA' },
    { value: 'HARYANA', label: 'HARYANA' },
    { value: 'CHATTISGARH', label: 'CHATTISGARH' },
    { value: 'ODISHA', label: 'ODISHA' },
    { value: 'HIMACHAL PRADESH', label: 'HIMACHAL PRADESH' },
    { value: 'PUNJAB', label: 'PUNJAB' },
    { value: 'WEST BENGAL', label: 'WEST BENGAL' },
    { value: 'KARNATAKA', label: 'KARNATAKA' },
    { value: 'JHARKHAND', label: 'JHARKHAND' },
    { value: 'ANDHRA PRADESH', label: 'ANDHRA PRADESH' },
    { value: 'BIHAR', label: 'BIHAR' },
    { value: 'KERALA', label: 'KERALA' },
    { value: 'TAMIL NADU', label: 'TAMIL NADU' },
    { value: 'MEGHALAYA', label: 'MEGHALAYA' },
    { value: 'MIZORAM', label: 'MIZORAM' },
    { value: 'ASSAM', label: 'ASSAM' },
    { value: 'NAGALAND', label: 'NAGALAND' },
    { value: 'TELANGANA', label: 'TELANGANA' },
    { value: 'ARUNACHAL PRADESH', label: 'ARUNACHAL PRADESH' },
    { value: 'MANIPUR', label: 'MANIPUR' },
    { value: 'SIKKIM', label: 'SIKKIM' },
    { value: 'TRIPURA', label: 'TRIPURA' },
    { value: 'JAMMU & KASHMIR', label: 'JAMMU & KASHMIR' },
    { value: 'OTHER', label: 'OTHER' },
  ];
  
  useEffect(() => {
    console.log("useEffect triggered with profileData:", profileData);
    if (profileData && Object.keys(profileData).length > 0) {
          setFormData({
        fullName: profileData.fullName || "",
        employeeNo: profileData.employeeNo || "",
        mobileNumber: profileData.mobileNumber || "",
        email: profileData.email || "",
        category: profileData.category || "",
        post: profileData.post || "",
        currentPosting: profileData.currentPosting || "",
        desiredPosting: profileData.desiredPosting || [],
        proofFile: null,
        additionalDetail: profileData.additionalDetail || "",
      });
  
      setSelectedOptions(
        profileData.desiredPosting
          ? profileData.desiredPosting.map((val) => ({
              value: val,
              label: val,
            }))
          : []
      );
    }
  }, [profileData]);
  const filteredOptions = options.filter(
    (option) => option.value !== formData.currentPosting
  );
  const handleChanges = (selected) => {
    setSelectedOptions(selected);
    setFormData({ ...formData, desiredPosting: selected.map((opt) => opt.value) });
  };


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "proofFile") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "desiredPosting") {
        formDataToSend.append(key, formData[key]); // Send desiredPosting as a JSON string
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });
    const goalToSend = profileData && profileData.selectedGoal ? profileData.selectedGoal : selectedOption;
    formDataToSend.append("selectedGoal", goalToSend);
    // formDataToSend.append("selectedGoal", selectedOption); // Add selected option to the form data

    try {
      const token = localStorage.getItem("authToken"); // Retrieve token from local storage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
  
      let response;
      if (profileData && profileData._id) {
        // If profileData exists, update the record
        response = await api.put(
          `/mutual-transfer/update/${profileData._id}`, 
          formDataToSend,
          { headers }
        );
        alert("Form updated successfully!");
      } else {
       response = await api.post(
        "/mutual-transfer/save",
        formDataToSend,
        { headers }
      );
      alert("Form submitted successfully!");
    }

      navigate(-1); // Go to the previous page
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting the form:", error.message);
      alert("There was an error submitting your form. Please try again.");
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };

  return (
    <div className="form flex justify-center items-center bg-gray-100">
      <div className="bg-white p-10 rounded shadow-md w-full mt-10 mb-10 max-w-3xl">
        <h2 className="form text-xl font-semibold mb-6">
          Personal information to find a good match
        </h2>
        <hr className="h-[1px] bg-gray-600 mb-5" />
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 gap-y-10">
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Full Name *</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Employee Permanent No. *
              </label>
              <input
                type="text"
                name="employeeNo"
                value={formData.employeeNo}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Mobile Number *
              </label>
              <input
                type="tel"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Email *</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Category *</label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
                <option value="">Select Category</option>
                <option value="UR">UR</option>
                <option value="OBC">OBC</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
              </select>
            </div>
            <div className="flex text-sm gap-3 flex-col">
  <label className="block text-sm text-gray-700">Post *</label>
  <select
    name="post"
    value={formData.post}
    onChange={handleChange}
    className="mt-1 p-2 border border-gray-300 rounded w-full"
    required
  >
    <option value="">Select Post</option>
    <option value="Tax Assistant – CBEC">Tax Assistant – CBEC</option>
    <option value="Tax Assistant – CBDT">Tax Assistant – CBDT</option>
    <option value="CGDA Auditor">CGDA Auditor</option>
    <option value="Income Tax Inspectors">Income Tax Inspectors</option>
    <option value="Excise Inspectors">Excise Inspectors</option>
    <option value="Divisional Accountant">Divisional Accountant</option>
    <option value="CGA Accountant">CGA Accountant</option>
    <option value="CAG Accountant">CAG Accountant</option>
    <option value="Others Postal and Telecom Accountants">
      Others Postal and Telecom Accountants
    </option>
    <option value="Examiner / Preventive Officer">
      Examiner / Preventive Officer
    </option>
    <option value="UDC">UDC</option>
    <option value="LDC">LDC</option>
    <option value="Sub Inspectors – CBI">Sub Inspectors – CBI</option>
    <option value="CSS Assistants">CSS Assistants</option>
    <option value="Inspector Of Post">Inspector Of Post</option>
    <option value="Statistical Investigator">Statistical Investigator</option>
    <option value="Others Assistant – 4600 and 4200">
      Others Assistant – 4600 and 4200
    </option>
    <option value="Stenographer">Stenographer</option>
    <option value="DEO">DEO</option>
    <option value="CAG Auditor">CAG Auditor</option>
    <option value="AAO (Accounts)">AAO (Accounts)</option>
    <option value="AAO (Audit)">AAO (Audit)</option>
    <option value="OTHER">OTHER</option>
  </select>
</div>

<div className="flex text-sm gap-3 flex-col">
        <label className="block text-sm text-gray-700">Current Posting *</label>
        <select
          name="currentPosting"
          value={formData.currentPosting}
          onChange={handleChange}
          className="mt-1 p-2 border border-gray-300 rounded w-full"
          required
        >
          <option value="">Select Current Posting</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>


<div className="flex text-sm gap-3 flex-col">
        <label className="block text-sm text-gray-700">
          Desired Posting Preference(s) *
        </label>
        <Select
          isMulti  // Allow multiple selections
          name="postings"
          options={filteredOptions} // Use the filtered options
          value={selectedOptions}
          onChange={handleChanges}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          closeMenuOnSelect={false}  // Keep the menu open after selection
        />
        
</div>
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Upload Proof * (Gov ID/Salary slip/Appt letter)
              </label>
              <input
                type="file"
                name="proofFile"
                accept=".jpg,.pdf"
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>
          </div>

          <div className="flex text-sm gap-3 flex-col">
            <label className="block text-sm text-gray-700">
              Additional Detail
            </label>
            <textarea
              name="additionalDetail"
              value={formData.additionalDetail}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              rows="4"
            ></textarea>
          </div>

          {/* <p className="text-xs text-center text-red-500">
            Please note, you can't modify the details once submitted.
          </p> */}

<div className="flex justify-center">
        <button
          type="submit"
          className="w-[179px] bg-[#8C7147] text-white p-2 rounded"
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? "Submitting..." : "Submit"} {/* Show loading text */}
        </button>
      </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfoForm;
