import React, { useEffect, useState,useRef } from "react";
import SSC from "../assets/SSC.png";
import "./navbar.css";
import { useNavigate } from "react-router-dom"; // import useNavigate for redirection
import api from "../apiConfig/apiConfig";
import {load} from '@cashfreepayments/cashfree-js'

const SuggestedCourse = ({ selectedOptions }) => {
  const [customSchedule, setCustomSchedule] = useState(null);
  const [similarSchedules, setSimilarSchedules] = useState([]);
  const [otherExams, setOtherExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Move useNavigate here
  const [orderId, setOrderId] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [showPromoInput, setShowPromoInput] = useState(false);

  const cashfreeRef = useRef(null); // Use ref to store cashfree instance

  // Initialize Cashfree SDK
  useEffect(() => {
    const initializeSDK = async () => {
      cashfreeRef.current = await load({ mode: "sandbox" }); // Store in ref
    };
    initializeSDK();
  }, []);

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        if (!selectedOptions) {
          throw new Error("Selected options are missing!");
        }
        const response = await api.post(
          "/schedule/match",
          selectedOptions
        );
        const { customSchedule, similarSchedules, otherExams } = response.data;
        setCustomSchedule(customSchedule || null);
        setSimilarSchedules(similarSchedules || []);
        setOtherExams(otherExams || []);
      } catch (err) {
        console.error("Error fetching schedules:", err.message);
        setError("Failed to load schedules. Please try again later.");
      }finally {
        setLoading(false);
      }
    };

    fetchSchedules(); // Automatically send data to server
  }, [selectedOptions]);

  const getSessionId = async (schedule) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        localStorage.setItem("submissionData", JSON.stringify(selectedOptions));
        alert("You need to log in to make a purchase.");
        navigate("/login");
        return null;
      }

      const response = await api.post(
        "/purchase",
        {
          scheduleId: schedule.id,
          goalId: schedule.goalId,
          goal: schedule.goal,
          duration: schedule.duration,
          exam: schedule.exam,
          features: schedule.features,
          originalPrice: schedule.originalPrice, // Pass original price
          discountedPrice: schedule.discountedPrice, // Pass discounted price
          promoCode: schedule.appliedPromoCode,
          stage: schedule.stage,
          maxLeaves: schedule.maxLeaves,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data && response.data.payment_session_id) {
        setOrderId(response.data.order_id);
        // return response.data.payment_session_id;
        return { sessionId: response.data.payment_session_id, orderId: response.data.order_id };

      } else {
        alert("Failed to generate payment session ID.");
        return null;
      }
    } catch (error) {
      console.error("Payment Error:", error.message);
      alert("Payment initiation failed. Please try again later.");
      return null;
    }
  };
// Use useEffect to log orderId after it's updated
useEffect(() => {
  if (orderId) {
    console.log(orderId, "hvhg"); // Now logs the correct value
  }
}, [orderId]);
  // Verify payment after completion
  const verifyPayment = async (schedule,passedOrderId) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      localStorage.setItem("submissionData", JSON.stringify(selectedOptions));
      alert("You need to log in to make a purchase.");
      navigate("/login");
      return null;
    }
    try {
      const res = await api.post("/verify", { orderId :passedOrderId,
        scheduleId: schedule.id,
        goalId: schedule.goalId,
        goal: schedule.goal,
        duration: schedule.duration,
        exam: schedule.exam,
        features: schedule.features,
        originalPrice: schedule.originalPrice ?? schedule.price, // Ensure original price is always included
        discountedPrice: schedule.discountedPrice ?? schedule.price, // Ensure discounted price is always included
        promoCode: schedule.appliedPromoCode ?? "",
        stage: schedule.stage,
        maxLeaves: schedule.maxLeaves,
       },
       {
         headers: { Authorization: `Bearer ${token}` },
       });
       if (res?.status === 200 && res?.data?.message) {
        console.log(res.data);
        alert("Payment verified successfully!");
        navigate("/");
      } else {
        console.log("Payment verification failed:", res?.data?.error);
        alert("Payment verification failed! Please try again.");
      }
      
    } catch (error) {
      console.error("Verification Error:", error.message);
    }
  };
  const handlePurchase = async (schedule) => {
    try {
      setLoading(true);
  
      const { sessionId, orderId } = await getSessionId({
        ...schedule,
        originalPrice: schedule.originalPrice ?? schedule.price, 
        discountedPrice: schedule.discountedPrice ?? schedule.price,
        appliedPromoCode: schedule.appliedPromoCode ?? "", 
      });  
      if (!sessionId) {
        console.error("Error: sessionId is undefined or null");
        return;
      }
  
      let checkoutOptions = {
        paymentSessionId: sessionId,
        redirectTarget: "_modal", // "_self" for same page, "_blank" for new tab
      };
  
      if (!cashfreeRef.current) {
        alert("Cashfree SDK not initialized. Please refresh and try again.");
        return;
      }

      cashfreeRef.current.checkout(checkoutOptions)
      .then(() => {
        verifyPayment({
          ...schedule,
          originalPrice: schedule.originalPrice ?? schedule.price, // Ensure it's sent in verification
          discountedPrice: schedule.discountedPrice ?? schedule.price,
          appliedPromoCode: schedule.appliedPromoCode ?? "",
        }, orderId);
      }).catch((checkoutError) => {
        console.error("Error initializing checkout:", checkoutError);
      });
    } catch (error) {
      console.error("Checkout Error:", error.message);
    } finally {
      console.log("handlePurchase execution completed");
      setLoading(false);
    }
  };
  const handleBuyNow = (schedule) => {
    setSelectedSchedule(schedule);
    setShowModal(true);
    setPromoCode("");
    setDiscount(0);
    setPromoError("");
  };

const applyPromoCode = async () => {
  setLoadingPromo(true);
  setPromoError("");

  try {
    const response = await api.post("/promos/validate", { code: promoCode });
    if (response.data && response.data.discount !== undefined) {
      const discountedPrice = (selectedSchedule.price - (selectedSchedule.price * response.data.discount) / 100).toFixed(2);
      setSelectedSchedule((prev) => ({
        ...prev,
        originalPrice: prev.price, // Store original price
        discountedPrice, // Store new discounted price
        appliedPromoCode: promoCode,
      }));
      setDiscount(response.data.discount);
    } else {
      setPromoError(response.data.message || "Invalid promo code");
    }
  } catch (error) {
    console.error("Promo Code Error:", error.message);
    setPromoError("Invalid Promo Code");
  } finally {
    setLoadingPromo(false);
  }
};
  

  if (error) return <p className="text-red-500">{error}</p>;
  // if (!customSchedule) return <p>Loading schedules...</p>;

  return (
    <div className="container mx-auto p-4 md:px-[4rem] lg:px-[4rem] animate-fadeIn">
      <h2 className="form text-lg font-semibold mb-4">Your customized schedule</h2>
      <div className={`grid grid-cols-1 ${loading ? "md:grid-cols-4" : "md:grid-cols-3"} gap-4 mb-[3rem]`}>
  {loading ? (
    <LoadingAnimation />
  ) : customSchedule ? (
    <ScheduleCard schedule={customSchedule} isPrimary={true} index={0} onPurchase={handleBuyNow} />
  ) : (
    <p className="text-gray-500">No schedule found</p>
  )}
</div>

      <h3 className="form text-lg font-semibold mt-8 mb-4">Similar study schedules</h3>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-[3rem]">
      {loading ? (
          <LoadingAnimation />
        ) : similarSchedules.length > 0 ? (
          similarSchedules.map((schedule, idx) => (
            <ScheduleCard key={idx} schedule={schedule} index={idx + 1} onPurchase={handleBuyNow} />
          ))
        ) : (
          <p className="text-gray-500">No schedule found</p>
        )}
      </div>
      <div>
      {showModal && selectedSchedule && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
      <h2 className="text-xl font-semibold mb-4">{selectedSchedule.exam} {selectedSchedule.stage}</h2>
      <p className="text-gray-700 mb-2">{selectedSchedule.duration.replace("-", " ").replace(/d/, "D")}</p>
      <p className="text-gray-700 mb-2">{selectedSchedule.hrsanddays} Hours / Day</p>
      <p className="text-gray-700 mb-4">
        Price: ₹ 
        {discount > 0 ? (
          <>
            <span className="text-gray-500 line-through mr-2">
              {selectedSchedule.price.toFixed(2)}
            </span>
            <span className="text-red-600 font-semibold">
              ₹{(selectedSchedule.price - (selectedSchedule.price * discount) / 100).toFixed(2)}
            </span>
            <span className="text-green-600 text-sm ml-2">
              ({discount}% OFF)
            </span>
          </>
        ) : (
          selectedSchedule.price
        )}
      </p>

      {/* Promo Code Section */}
      {!showPromoInput ? (
        <p 
          className="text-blue-600 underline cursor-pointer mb-4" 
          onClick={() => setShowPromoInput(true)}
        >
          Have a promo code?
        </p>
      ) : (
        <div className="flex items-center gap-2 mb-4">
          <input
            type="text"
            placeholder="Enter promo code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="border p-2 w-full"
          />
          <button
            onClick={applyPromoCode}
            className={`px-4 py-2 rounded ${
              promoCode.trim() 
                ? "bg-green-600 text-white cursor-pointer"
                : "bg-gray-400 text-gray-200 cursor-not-allowed"
            }`}
            disabled={!promoCode.trim() || loadingPromo}
          >
            {loadingPromo ? "Applying..." : "Apply"}
          </button>
        </div>
      )}

      {promoError && <p className="text-red-500 text-sm">{promoError}</p>}
      
      <div className="flex justify-end gap-4">
        <button onClick={() => setShowModal(false)} className="bg-gray-400 text-white px-4 py-2 rounded">
          Cancel
        </button>
        <button 
          onClick={() => handlePurchase(selectedSchedule)} 
          className="bg-green-600 text-white px-4 py-2 rounded"
        >
          Purchase
        </button>
      </div>
    </div>
  </div>
)}

  </div>
    </div>
  );
};

const ScheduleCard = ({ schedule, isPrimary = false, index, onPurchase }) => (
  <div
    className={`rounded-lg shadow p-5 ${isPrimary ? "bg-green-100" : "bg-gray-100"}`}
    style={{ animationDelay: `${index * 0.2}s` }}
  >
    <div className="flex gap-3 mb-2">
      <img className="h-[40px] w-[40px]" src={SSC} alt="SSC" />
      <div className="flex flex-col">
        <div className="heading text-[#364374] font-bold flex items-center gap-1">
        <p className="font-bold">{schedule.duration.replace("-", " ").replace(/d/, "D")}</p>
        <p className="font-bold">|</p>
          <p className="text-sm">{schedule.hrsanddays} Hours / Day</p>
        </div>
        <p className="form text-[0.7rem] mb-1">{schedule.exam} {schedule.stage}</p>
      </div>
    </div>
    <hr className="h-[2px] mb-[1.5rem] rounded-md bg-black" />
    <ul className="form text-sm mb-6">
      {schedule.features.map((feature, idx) => (
        <li key={idx} className="flex text-sm font-semibold items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1 text-[#1A6400]"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <div className="flex justify-end gap-[1.5rem] items-center">
      <p className="form font-bold">₹{schedule.price}</p>
      <button
        className="form bg-[#1A6400] text-white px-4 py-2 rounded"
        onClick={() => onPurchase(schedule)}
      >
        Buy Now
      </button>
    </div>
  </div>
);

const LoadingAnimation = () => (
  <div className="flex justify-center items-center w-full">
    <div className="w-10 h-10 border-4 border-green-500 border-dashed rounded-full animate-spin"></div>
  </div>
);

export default SuggestedCourse;
