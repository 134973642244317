import React, { useState, useEffect } from "react";
import "./navbar.css";
import api from "../apiConfig/apiConfig";

const ExpertsCard = () => {
  const [experts, setExperts] = useState([]);

  // Fetch experts data from the server on component mount
  useEffect(() => {
    const fetchExperts = async () => {
      try {
        const response = await api.get("/experts");
        setExperts(response.data); // Store the fetched data in state
      } catch (error) {
        console.error("Failed to fetch experts:", error);
      }
    };

    fetchExperts();
  }, []);

  return (
    <div className="bg-[#E9E7C4] p-10">
      <h1 className="text-center heading text-3xl  mt-8 mb-[3rem] baloo-font">
        Meet our IITian Experts
      </h1>
      <div className="overflow-x-auto whitespace-nowrap scrollbar-hide mb-5 mt-10">
        <div className="inline-flex space-x-6 mb-10">
          {experts.length > 0 ? (
            experts.map((expert, index) => (
              <div
                key={index}
                className="bg-white heading shadow-md rounded-md py-6 w-64 text-left inline-block overflow-hidden"
              >
                <div className="h-36 flex justify-center items-center mb-4 px-4">
                  <img
                    src={expert.imageUrl}
                    alt={`${expert.name}'s image`}
                    className="w-3/4 h-full rounded-md object-cover"
                  />
                </div>
                <hr className="h-[1px] bg-black mb-4" />
                <div className="flex flex-col justify-start items-start px-6 gap-2 w-full">
                  <h2 className="font-bold text-lg w-full break-words overflow-hidden whitespace-normal">
                    {expert.name}
                  </h2>
                  <li className="text-sm mt-2 w-full break-words overflow-hidden whitespace-normal">
                    {expert.degree}
                  </li>
                  <li className="text-sm w-full break-words overflow-hidden whitespace-normal">
                    {expert.experience}
                  </li>
                </div>
              </div>
            ))
          ) : (
            <p>No experts available at the moment.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpertsCard;
