import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../apiConfig/apiConfig";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [courseInfo, setCourseInfo] = useState(null); // For storing course and resource info
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState(""); // Store mobile number
  const [loading, setLoading] = useState(false);

  // Access goalId from the location state
  const goalId = location.state?.goalId;

  useEffect(() => {
    // If goalId is available, fetch course info
    if (goalId) {
      const fetchCourseInfo = async () => {
        try {
          const token = localStorage.getItem("authToken"); // Retrieve token from local storage

          const response = await api.get(`/course-content/aboutdata/${goalId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          setCourseInfo(response.data);
          console.log(response.data)
        } catch (err) {
          setError("Failed to fetch course information.");
          console.error("Error fetching course info:", err);
        }
      };

      fetchCourseInfo();
    }
  }, [goalId]);

  const handleAgreeClick = () => {
    // Move to step 2 (Form step)
    if (!mobileNumber) {
      alert("Please enter your mobile number");
      return;
    }
    setStep(2);
  };

  const handleBeginClick = async () => {
    if (!goalId || !mobileNumber) {
      console.error("Goal ID or Mobile Number is missing");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("authToken");
      await api.post(
        "/user/save-mobile-number",
        { goalId, mobileNumber },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(`/schedule/${goalId}`, { replace: true });
    } catch (error) {
      console.error("Error saving mobile number:", error);
      alert("Failed to save mobile number. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center p-4">
        <div className="bg-white w-full p-6 space-y-6">
          {/* <div className="flex items-center space-x-4">
            <h1 className="text-2xl font-bold">Privacy Policy</h1>
          </div> */}

          <div className="space-y-4 text-sm">
            {step === 1 && (
              <>
      <div className="flex flex-col space-y-2">
        <h2 className="text-lg font-semibold">Enter Mobile Number</h2>
        <input
          type="tel"
          placeholder="Enter your mobile number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          className="border p-3 w-32 rounded text-sm"
          />
      </div>

      {/* Privacy Policy Section */}
      <div className="flex items-center space-x-4">
            <h1 className="text-2xl font-bold">Privacy Policy</h1>
          </div>
                <p>
                  Welcome to our Privacy Policy page. We are committed to protecting
                  your personal information and your right to privacy.
                </p>
                <h2 className="text-lg font-semibold">Information We Collect</h2>
                <p>
                  We collect personal information that you provide to us such as
                  name, address, contact information, passwords and security data,
                  and payment information.
                </p>
                <h2 className="text-lg font-semibold">
                  How We Use Your Information
                </h2>
                <p>
                  We use personal information collected via our website for a
                  variety of business purposes described below. We process your
                  personal information for these purposes in reliance on our
                  legitimate business interests, in order to enter into or perform a
                  contract with you, with your consent, and/or for compliance with
                  our legal obligations.
                </p>
                <h2 className="text-lg font-semibold">
                  Will Your Information Be Shared With Anyone?
                </h2>
                <p>
                  We only share information with your consent, to comply with laws,
                  to provide you with services, to protect your rights, or to
                  fulfill business obligations.
                </p>
                <h2 className="text-lg font-semibold">
                  How Long Do We Keep Your Information?
                </h2>
                <p>
                  We keep your information for as long as necessary to fulfill the
                  purposes outlined in this privacy policy unless otherwise required
                  by law.
                </p>
                <h2 className="text-lg font-semibold">
                  How Do We Keep Your Information Safe?
                </h2>
                <p>
                  We aim to protect your personal information through a system of
                  organizational and technical security measures.
                </p>

                <div className="flex justify-end">
                  <button
                    onClick={handleAgreeClick}
                    className="hover:bg-[#202844] text-white px-4 py-2 rounded bg-[#364374] transition duration-300"
                  >
                    Agree and Continue
                  </button>
                </div>
              </>
            )}

{step === 2 && (
  <div className="h-screen flex flex-col justify-center">
    {/* Display the fetched course information */}
    {courseInfo ? (
      <div className="h-full flex flex-col">
        {/* About the Schedule */}
        <div className="h-1/2 p-4 border rounded-lg shadow-md bg-gray-100 mb-6 overflow-auto">
          <h2 className="text-lg font-semibold mb-2">About the Schedule (Course Information)</h2>
          <ul className="space-y-2">
          {Array.isArray(courseInfo.courseInfo)
            ? courseInfo.courseInfo.flatMap((item, index) =>
                item.split(',').map((subItem, subIndex) => (
                  <li key={`${index}-${subIndex}`} className="mb-2">• {subItem.trim()}</li>
                ))
              )
            : courseInfo.courseInfo.split(',').map((subItem, index) => (
                <li key={index}>• {subItem.trim()}</li>
              ))}
          </ul>
        </div>

        {/* Resources Section */}
        <div className="h-1/2 p-4 border rounded-lg shadow-md bg-gray-100 overflow-auto">
          <h2 className="text-lg font-semibold mb-2">Resources You Should Have</h2>
          <ul className="space-y-2">
          {Array.isArray(courseInfo.resources)
            ? courseInfo.resources.flatMap((resource, index) =>
                resource.split(',').map((subResource, subIndex) => (
                  <li key={`${index}-${subIndex}`} className="mb-2">• {subResource.trim()}</li>
                ))
              )
            : courseInfo.resources.split(',').map((subResource, index) => (
                <li key={index}>• {subResource.trim()}</li>
              ))}
          </ul>
        </div>
      </div>
    ) : error ? (
      <p>{error}</p>
    ) : (
      <p>Loading course information...</p>
    )}

    <div className="flex justify-end mt-6">
      <button
        onClick={handleBeginClick}
        className="hover:bg-[#202844] text-white px-4 py-2 rounded bg-[#364374] transition duration-300"
      >
        Let's Begin
      </button>
    </div>
  </div>
)}

          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
