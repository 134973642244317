import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../apiConfig/apiConfig";

const ScheduleComponent = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    successRate: "0", // Static values for now
    happyStudents: "0",
    schedules: "0",
    scheduleList: [],
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status

  useEffect(() => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage

    if (!token) {
      setIsLoggedIn(false); // If no token, set logged-in status to false
      return;
    }

    // If token exists, proceed with fetching the schedules
    setIsLoggedIn(true); // User is logged in
    const fetchPurchasedSchedules = async () => {
      try {
        const response = await api.get("/purchased", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);

        // Transform the purchased schedules to the desired format
        const scheduleList = response.data.purchasedSchedules.map((schedule) => ({
          purchaseId: schedule._id, // Unique ID for each purchase
          goalId: schedule.goalId, // Store goal in the data
          name: `${schedule.exam}`, // Exam and goal combined
          duration: schedule.duration, // Use the duration field from the response
          purchasedAt: new Date(schedule.purchasedAt), // Store date for sorting
        }))
        .sort((a, b) => b.purchasedAt - a.purchasedAt); // Sort by newest first


        setData((prevState) => ({
          ...prevState,
          scheduleList, // Updated scheduleList
        }));
      } catch (error) {
        console.error("Error fetching purchased schedules:", error.message);
      }
    };

    fetchPurchasedSchedules();
  }, []);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await api.get("/statistics");
        if (response.data.success) {
          setData((prevState) => ({
            ...prevState,
            ...response.data.data,
          }));
        }
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
      }
    };

    fetchStatistics();
  }, []);

  return (
    <div className="text-center p-8 bg-gray-100">
      {/* Stats Section */}
      <div className="flex heading justify-around mb-[4rem] mt-12 transform transition-all duration-1000 ease-in-out baloo-font ">
        <div>
          <h1 className="text-7xl font-bold text-[#DCD300] ">{data.schedules}+</h1>
          <p className="text-xl form ">Schedules</p>
        </div>

        <div>
          <h1 className="text-7xl font-bold text-[#DCD300]">{data.happyStudents}+</h1>
          <p className="text-xl form">Happy students</p>
        </div>
        <div>
          <h1 className="text-7xl font-bold text-[#DCD300]">{data.successRate}+</h1>
          <p className="text-xl form">Mutual Matches</p>
        </div>
      </div>

      {/* Schedule List */}
      <div className="bg-white flex flex-col justify-items-center items-center p-6 rounded-lg shadow-lg max-w-xl mx-auto mb-10 mt-[6rem]">
        <h2 className="text-3xl heading  mb-6 baloo-font">
          Your Schedules
        </h2>
        {!isLoggedIn ? (
          <div className="flex flex-col items-center">
            <p className="mb-4">Please log in to view your schedules.</p>
            <button
              onClick={() => navigate("/login")}
              className="bg-[#1A6400] text-white px-16 py-2 rounded-lg text-xl w-full"
            >
              Login
            </button>
          </div>
        ) : (
          data.scheduleList.length > 0 ? (
            data.scheduleList.map((schedule, index) => (
              <Link
                to={`/schedule/${schedule.purchaseId}`}
                key={index}
                className="w-[60%] form mb-4 py-3 px-6 bg-[#1A6400] text-white rounded-lg flex justify-between items-center"
              >
                <span>{`${schedule.name} (${schedule.duration.replace("-", " ").replace(/d/, "D")})`}</span>
                <span>&gt;</span>
              </Link>
            ))
          ) : (
            <p className="text-gray-500 mt-4">No schedules yet purchased.</p>
          )
        )}
      </div>
    </div>
  );
};

export default ScheduleComponent;
