import React, { useEffect, useState } from "react";
import api from "../apiConfig/apiConfig"; // Import the Axios instance
import "./navbar.css";

const CommunityInsights = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch published feedbacks from the backend
  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await api.get("/feedbackpublished");
        setFeedbacks(response.data);
        console.log(response.data)
      } catch (err) {
        setError("Failed to fetch feedbacks. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, []);

  return (
    <div className="bg-gray-100 p-10">
      <h1 className="text-center text-3xl  mt-8 mb-10 baloo-font text-black">
        Community Insights
      </h1>

      {loading ? (
        <p className="text-center text-gray-700">Loading feedback...</p>
      ) : error ? (
        <p className="text-center text-red-600">{error}</p>
      ) : feedbacks.length === 0 ? (
        <p className="text-center text-gray-700">No published feedbacks available.</p>
      ) : (
        <div className="overflow-x-auto whitespace-nowrap scrollbar-hide mb-10">
          <div className="inline-flex space-x-6">
            {feedbacks.map((feedback, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-md p-6 w-[300px] inline-block mb-4 "
              >
                <h2 className="heading font-bold text-lg">{feedback.name}</h2>
                <div className="flex items-center mb-2">
                  {Array(feedback.rating)
                    .fill(0)
                    .map((_, i) => (
                      <span key={i} className="text-yellow-500">★</span>
                    ))}
                </div>
                <p className="ext-sm form text-wrap">{feedback.feedback}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunityInsights;
