import React, { useState, useEffect } from "react";
import { useNavigate ,Link} from "react-router-dom";
import api from "../apiConfig/apiConfig";
import SSC from "../assets/SSC.png";
import Railway from "../assets/Railway.png";
import Banking from "../assets/RRB.png";
import "../components/navbar.css";
import Navbar from "../components/Navbar";

const Transfer = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // Track selected option

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const formData = {
    title: "Choose your goal",
    options: [
      { name: "SSC", image: SSC, route: "/transfers/ssc" },
      { name: "RRB", image: Railway, route: "/transfers/rrb" },
      { name: "BANKING", image: Banking, route: "/transfers/banking" },
    ],
  };

  const handleOptionChange = async (option) => {
    setSelectedOption(option.name); // Update selected option
    setErrorMessage(""); // Clear previous error message

    const token = localStorage.getItem("authToken");

    try {
      const response = await api.post(
        "mutual-transfer/check-transfer",
        { selectedGoal: option.name },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        if (response.data.exists) {
          // Show message if transfer record already exists
          // setErrorMessage(response.data.message);
          setErrorMessage(
            <div className="text-red-500 text-center mt-3">
              A transfer record for this Organisation already exists. 
              Would you like to{" "}
              <Link
                to="/mutual-matches"
                className="text-blue-500 font-semibold underline"
              >
                update
              </Link>
              {" "}it?
            </div>
          );
        } else {
          // Proceed to the next screen if no existing record
          navigate(option.route, { state: { selectedOption: option.name } });
        }      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="max-w-xl p-4 mx-auto animate-fadeIn">
        <div className="form font-semibold w-full mt-5 flex justify-center">
          Select Organisation
        </div>

        {errorMessage && (
          <div className="text-red-500 text-center mt-3">{errorMessage}</div>
        )}

        {formData.options.map((option) => (
          <label
            key={option.name}
            className="container mb-7 max-w-lg mx-auto mt-5 h-[3rem] flex items-center bg-[#D9D9D9] rounded-md py-2 px-4 cursor-pointer hover:bg-gray-200 transition-colors"
          >
            <div className="flex items-center flex-1">
              <div className="w-8 h-8 rounded-full flex items-center justify-center mr-3">
                <img src={option.image} alt={`${option.name} logo`} />
              </div>
              <span className="text-sm font-medium text-gray-700">
                {option.name}
              </span>
            </div>

            <div className="ml-3 mr-2">
              <input
                type="radio"
                name="goal"
                value={option.name}
                checked={selectedOption === option.name} // Ensure only one is selected
                onChange={() => handleOptionChange(option)}
                className="mr-2"
              />
            </div>
          </label>
        ))}
      </div>
    </>
  );
};

export default Transfer;
