import { useState,useEffect } from "react";
import "../components/navbar.css";
import { useLocation,useNavigate } from "react-router-dom";
import api from "../apiConfig/apiConfig";

const RRB = () => {
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const location = useLocation();
  const navigate = useNavigate(); // For navigation to the previous page
  const selectedOption = location.state?.selectedOption || "No option selected";
  const profileData = location.state?.profileData || {}; // Access the passed data
  const [selectedOptions, setSelectedOptions] = useState([]);
  const goalToSend = profileData && profileData.selectedGoal ? profileData.selectedGoal : selectedOption;

console.log(selectedOption)
const departmentPosts = {
  "Accounts Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "SR.SO",
    "AA",
    "JAA",
    "AC",
    "JE IT",
    "SE IT",
    "PROGRAMMER",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Engineering Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "SE BRIDGE (P/WAY,W,DRW.)",
    "JE BRIDGE (P/WAY,W,DRW.)",
    "TECH-I",
    "TECH-II",
    "TECH-III",
    "TM-4/3/2/1",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Commercial Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "COMMERCIAL CUM TC",
    "Sr. COMMERCIAL CUM TC",
    "Ch. COMMERCIAL CUM TKT. SUPER.",
    "COMMERCIAL SUPERINTENDENT",
    "Jr. COMMERCIAL CLERK",
    "Sr. COMMERCIAL CLERK",
    "COMMERCIAL INSPECTOR",
    "Ch. COMMERCIAL INSPECTOR",
    "TICKET EXAMINER",
    "TTE, Sr. TTE",
    "TC",
    "TTI",
    "CTI",
    "SUPERVISOR",
    "Jr. CLERK/Sr. CLERK",
    "OS/Ch.OS",
    "TADAK",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Loco Guard Running Department": [
    "GUARD",
    "ASSIST GUARD",
    "OTHER"
  ],
  "Loco Pilot Running Department": [
    "ASSISTANT LOCO PILOT",
    "LOCO PILOT",
    "OTHER"
  ],
  "Personnel Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "Jr. CLERK/Sr. CLERK",
    "OS/Ch.OS",
    "GENERAL ASSISTANT",
    "WELFARE INSPECTOR",
    "OTHER"
  ],
  "Operating Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "POINTS MAN",
    "STATION MASTER",
    "SHUNTING JAMADAR",
    "CABIN MASTER",
    "TRAIN MANAGER",
    "PORTER",
    "OTHER"
  ],
  "Stores Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "Jr. CLERK/Sr. CLERK",
    "OS/Ch.OS",
    "CDMS",
    "DS/ADS",
    "DMS",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Electrical Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "SE ELECTRICAL",
    "JE ELECTRICAL",
    "TECH-I",
    "TECH-II",
    "TECH-III",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Signal & Telecommunication Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "SE TELE",
    "JE TELE",
    "SE SIGNAL",
    "JE SIGNAL",
    "TECH-I",
    "TECH-II",
    "TECH-III",
    "HELPER",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Mechanical Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "SE MECHANICAL",
    "JE MECHANICAL",
    "TECH-I",
    "TECH-II",
    "TECH-III",
    "HELPER",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "RPF & Security Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "CONSTABLE",
    "HD. CONSTABLE",
    "ASI",
    "SI",
    "INSPECTOR",
    "OTHER"
  ],
  "Safety Department": [
    "CONFIDENTIAL ASSISTANT",
    "SAFETY COUNSELOR",
    "SAFETY INSPECTOR",
    "OS/Ch.OS",
    "JR.CLERK/SR.CLERK",
    "OA",
    "OTHER"
  ],
  "Vigilance Department": [
    "Ch. VIGILANCE INSPECTOR",
    "VIGILANCE INSPECTOR",
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "SAFETY INSPECTOR",
    "OS/Ch.OS",
    "JR.CLERK/SR.CLERK",
    "OA",
    "OTHER"
  ],
  "General Administration Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "Jr. CLERK/Sr. CLERK",
    "OS/Ch.OS",
    "GENERAL ASSISTANT",
    "OTHER"
  ],
  "Medical Department": [
    "CONFIDENTIAL ASSISTANT",
    "STENO_GR-I/II/III",
    "DRESSER-1/2/3",
    "LAB SUPTD.",
    "LAB ASSIST.",
    "PHARMACIST-1/2/3/4",
    "RADIOGRAPHER-1/2/3",
    "HOSPITAL ASSIST.",
    "MULTI TASKING STAFF",
    "OA/GRP-D",
    "OTHER"
  ]
};
const zonesAndDivisions = {
  WR: [
    "Mumbai WR",
    "Ratlam",
    "Ahmedabad",
    "Rajkot",
    "Bhavnagar",
    "Vadodara",
  ],
  CR: [
    "Mumbai",
    "Bhusawal",
    "Pune",
    "Solapur",
    "Nagpur CR",
  ],
  SR: [
    "Chennai",
    "Tiruchirappalli",
    "Madurai",
    "Palakkad",
    "Salem",
    "Thiruvananthapuram",
  ],
  NR: [
    "Delhi",
    "Ambala",
    "Firozpur",
    "Lucknow NR",
    "Moradabad",
  ],
  SER: [
    "Adra",
    "Chakradharpur",
    "Kharagpur",
    "Ranchi",
  ],
  NER: [
    "Izzatnagar",
    "Lucknow NER",
    "Varanasi",
  ],
  NWR: [
    "Jaipur",
    "Ajmer",
    "Bikaner",
    "Jodhpur",
  ],
  SWR: [
    "Hubballi",
    "Bengaluru",
    "Mysuru",
  ],
  WCR: [
    "Jabalpur",
    "Bhopal",
    "Kota",
  ],
  ER: [
    "Howrah",
    "Sealdah",
    "Asansol",
    "Malda",
  ],
  "S COAST R": [
    "Waltair",
    "Vijayawada",
    "Guntur",
    "Guntakal",
  ],
  SECR: [
    "Bilaspur",
    "Raipur",
    "Nagpur SEC",
  ],
  ECR: [
    "Danapur",
    "Dhanbad",
    "Pt Deen Dayal Upadhyaya",
    "Samastipur",
    "Sonpur",
  ],
  SCR: [
    "Secunderabad",
    "Hyderabad",
    "Nanded",
  ],
  "E COAST R": [
    "Khurda Road",
    "Sambalpur",
    "Rayagada",
  ],
  NCR: [
    "Prayagraj",
    "Agra",
    "Jhansi",
  ],
  NFR: [
    "Alipurduar",
    "Katihar",
    "Rangiya",
    "Lumding",
    "Tinsukia",
  ],
  KONKAN: [
    "Karwar",
    "Ratnagiri",
  ],
  "METRO RAIL KOLKATA": ["Metro Rail Kolkata"],
};
  const [formData, setFormData] = useState({
    fullName: "",
    employeeNo: "",
    mobileNumber: "",
    email: "",
    category: "",
    post: "",
    currentPosting: "",
    desiredPosting: "",
    proofFile: null,
    additionalDetail: "",
    currentZone: "", // Added field
    currentDivision: "", // Added field
    desiredZone: "", // Added field
    desiredDivision: "", // Added field
    department: "",
  });
  const [filteredPosts, setFilteredPosts] = useState([]);
 useEffect(() => {
  console.log("useEffect triggered with profileData:", profileData);
  if (profileData && Object.keys(profileData).length > 0) {
        setFormData({
        fullName: profileData.fullName || "",
        employeeNo: profileData.employeeNo || "",
        mobileNumber: profileData.mobileNumber || "",
        email: profileData.email || "",
        category: profileData.category || "",
        post: profileData.post || "",
        currentPosting: profileData.currentPosting || "",
        currentZone: profileData.currentZone || "",
        currentDivision: profileData.currentDivision || "",
        desiredPosting: profileData.desiredPosting || [],
        desiredZone: profileData.desiredZone || [],
        desiredDivision: profileData.desiredDivision || [],
        proofFile: null,
        additionalDetail: profileData.additionalDetail || "",
        department: profileData.department || "",
      });
  
      setSelectedOptions(
        profileData.desiredPosting
          ? profileData.desiredPosting.map((val) => ({
              value: val,
              label: val,
            }))
          : []
      );
    }
  }, [profileData]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "proofFile") {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name === "department") {
      setFilteredPosts(departmentPosts[value] || []);
      setFormData({ ...formData, department: value, post: "" }); // Reset post when department changes
    } else if (name === "currentZone") {
      setFormData({
        ...formData,
        [name]: value,
        currentDivision: "", // Reset division when zone changes
      });
    }else if (name === "desiredZone") {
      setFormData({
        ...formData,
        [name]: value,
        desiredDivision: "", // Reset division when zone changes
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });
    const goalToSend = profileData && profileData.selectedGoal ? profileData.selectedGoal : selectedOption;
    formDataToSend.append("selectedGoal", goalToSend);
    // formDataToSend.append("selectedGoal", selectedOption); // Add selected option to the form data

    try {
      const token = localStorage.getItem("authToken"); // Retrieve token from local storage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      let response;
      if (profileData && profileData._id) {
        // If profileData exists, update the record
        response = await api.put(
          `/mutual-transfer/update/${profileData._id}`, 
          formDataToSend,
          { headers }
        );
        alert("Form updated successfully!");
      } else {
       response = await api.post(
        "/mutual-transfer/save",
        formDataToSend,
        { headers }
      );
      alert("Form submitted successfully!");
    }

      navigate(-1); // Go to the previous page
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting the form:", error.message);
      alert("There was an error submitting your form. Please try again.");
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };
  return (
    <div className="form flex justify-center items-center bg-gray-100">
      <div className="bg-white p-10 rounded shadow-md w-full mt-10 mb-10 max-w-3xl">
        <h2 className="form text-xl font-semibold mb-6">
          Personal information to find a good match
        </h2>
        <hr className="h-[1px] bg-gray-600 mb-5" />
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 gap-y-10">
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Full Name *</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Employee Permanent No. *
              </label>
              <input
                type="text"
                name="employeeNo"
                value={formData.employeeNo}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Mobile Number *
              </label>
              <input
                type="tel"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Email *</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Category *</label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
                <option value="">Select Category</option>
                <option value="UR/OBC">UR/OBC</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
              </select>
            </div>
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Department *</label>
              <select
                name="department"
                value={formData.department}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
                <option value="">Select Department</option>
                {Object.keys(departmentPosts).map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">Post *</label>
              <select
                name="post"
                value={formData.post}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
                <option value="">Select Post</option>
                {filteredPosts.map((post) => (
                  <option key={post} value={post}>
                    {post}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Current zone *
              </label>
              <select
                name="currentZone"
                value={formData.currentZone}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
          <option value="">Select Current Zone</option>
          {Object.keys(zonesAndDivisions).map((zone) => (
            <option key={zone} value={zone}>
              {zone}
            </option>
          ))}
        </select>
            </div>
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Current division *
              </label>
              <select
                name="currentDivision"
                value={formData.currentDivision}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
          <option value="">Select Current Division</option>
          {formData.currentZone &&
            zonesAndDivisions[formData.currentZone].map((division) => (
              <option key={division} value={division}>
                {division}
              </option>
            ))}
        </select>

            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Current posting station *
              </label>
              <input
                name="currentPosting"
                value={formData.currentPosting}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
                <p className="text-xs text-red-500 mt-1">
                Please ensure the spelling is correct to avoid any discrepancies.
                </p>
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Desired zone *
              </label>
              <select
                name="desiredZone"
                value={formData.desiredZone}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
          <option value="">Select Desired Zone</option>
          {Object.keys(zonesAndDivisions).map((zone) => (
            <option key={zone} value={zone}>
              {zone}
            </option>
          ))}
        </select>
            </div>
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Desired division 
              </label>
              <select
                name="desiredDivision"
                value={formData.desiredDivision}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              >
          <option value="">Select Desire Division</option>
          {formData.desiredZone &&
            zonesAndDivisions[formData.desiredZone].map((division) => (
              <option key={division} value={division}>
                {division}
              </option>
            ))}
        </select>
            </div>
            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Desired posting station(s) 
              </label>
              <input
                name="desiredPosting"
                value={formData.desiredPosting}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
                <p className="text-xs text-red-500 mt-1">
                Ensure correct spelling and separate multiple stations with commas.  </p>
            </div>

            <div className="flex text-sm gap-3 flex-col">
              <label className="block text-sm text-gray-700">
                Upload Proof * (Gov ID/Salary slip/Appt letter)
              </label>
              <input
                type="file"
                name="proofFile"
                accept=".jpg,.pdf"
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>
          </div>

          <div className="flex text-sm gap-3 flex-col">
            <label className="block text-sm text-gray-700">
              Additional Detail
            </label>
            <textarea
              name="additionalDetail"
              value={formData.additionalDetail}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              rows="4"
            ></textarea>
          </div>

          {/* <p className="text-xs text-center text-red-500">
            Please note, you can't modify the details once submitted.
          </p> */}

<div className="flex justify-center">
        <button
          type="submit"
          className="w-[179px] bg-[#8C7147] text-white p-2 rounded"
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? "Submitting..." : "Submit"} {/* Show loading text */}
        </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RRB;
