import React, { useState, useEffect,useRef } from "react";
import Navbar from "../components/Navbar";
import editIcon from "../assets/editing.png";
import "../components/navbar.css";
import { FaTrash } from "react-icons/fa";
import api from "../apiConfig/apiConfig"; // Axios instance setup
import { useNavigate } from "react-router-dom";
import { Switch } from "@radix-ui/react-switch";
import {load} from '@cashfreepayments/cashfree-js'

const TransferProfile = () => {
  const [profiles, setProfiles] = useState([]);
  const [matchingResults, setMatchingResults] = useState([]);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(true);
  const [isLoadingMatches, setIsLoadingMatches] = useState(true);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(true);

  const cashfreeRef = useRef(null); // Use ref to store cashfree instance

  // Initialize Cashfree SDK
  useEffect(() => {
    const initializeSDK = async () => {
      cashfreeRef.current = await load({ mode: "sandbox" }); // Store in ref
    };
    initializeSDK();
  }, []);


  useEffect(() => {
    fetchProfiles();
    fetchMatchingResults();
  }, []);

  const fetchProfiles = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const { data: profilesData } = await api.get("/mutual-transfer/profile", {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(profilesData)
      setProfiles(profilesData);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    } finally {
      setIsLoadingProfiles(false);
    }
  };

  const fetchMatchingResults = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const { data: resultsData } = await api.get("/mutual-transfer/matchingResults", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMatchingResults(resultsData);
    } catch (error) {
      console.error("Error fetching matching results:", error);
    } finally {
      setIsLoadingMatches(false);
    }
  };

  const handleEditClick = async (profile) => {
    setSelectedProfile(profile);
    setEditModalVisible(true);
    setIsEditing(true); // Start loading
  
    try {
      const token = localStorage.getItem("authToken");
      const response = await api.post(
        "/mutual-transfer/confirm-edit",
        { profileId: profile._id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.hasPaid == true) {
        console.log(response.data.profile)
        navigate(`/transfers/${profile.selectedGoal}`, {     state: { profileData: response.data.profile }, // Pass data in state
        });
      }
    } catch (error) {
      console.error("", error);
    } finally {
      setIsEditing(false); // Stop loading
    }
  };
  
  const handleCloseModal = () => {
    setEditModalVisible(false);
  };

  const toggleProfileVisibility = async (profileId, currentVisibility) => {
    try {
      const token = localStorage.getItem("authToken");
      const updatedProfile = await api.put(
        `/mutual-transfer/${profileId}/visibility`,
        { isProfileVisible: !currentVisibility },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProfiles(profiles.map((profile) =>
        profile._id === profileId ? { ...profile, isProfileVisible: updatedProfile.data.isProfileVisible } : profile
      ));
    } catch (error) {
      console.error("Error toggling profile visibility:", error);
    }
  };
  // const handlePayment = async (profileId, selectedGoal,price) => {
  //   try {
  //     const token = localStorage.getItem("authToken");
  //     const response = await api.post(
  //       "/mutual-transfer/confirm-payment",
  //       { profileId,price },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );
  
  //     if (response.status === 200) {
  //       window.location.href = response.data.redirectUrl; // Redirect to the transfers page
  //     }
  //   } catch (error) {
  //     console.error("Payment confirmation failed:", error);
  //     alert("Payment failed. Please try again.");
  //   }
  // };

    const getSessionId = async (profileId, selectedGoal,price) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await api.post(
        "/mutual-transfer/confirm-payment",
        { profileId,price },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data && response.data.payment_session_id) {
        setOrderId(response.data.order_id);
        // return response.data.payment_session_id;
        return { sessionId: response.data.payment_session_id, orderId: response.data.order_id };

      } else {
        alert("Failed to generate payment session ID.");
        return null;
      }
    } catch (error) {
      console.error("Payment Error:", error.message);
      alert("Payment initiation failed. Please try again later.");
      return null;
    }
  };

  // Verify payment after completion
  const verifyPayment = async (profileId,passedOrderId) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/login");
      return null;
    }
    try {
      const res = await api.post("/mutual-transfer/verify", { profileId ,orderId :passedOrderId
       },
       {
         headers: { Authorization: `Bearer ${token}` },
       });
       if (res?.status === 200 && res?.data?.message) {
        console.log(res.data);
        navigate(res.data.redirectUrl, { state: { profileData: res.data.profile } });

      } else {
        console.log("Payment verification failed:", res?.data?.error);
        alert("Payment verification failed! Please try again.");
      }
      
    } catch (error) {
      console.error("Verification Error:", error.message);
    }
  };
  const handlePayment = async (profileId, selectedGoal,price) => {
    try {
      setLoading(true);
  
      const { sessionId, orderId } = await getSessionId(profileId, selectedGoal,price);
      console.log("Received sessionId:", sessionId);
  
      if (!sessionId) {
        console.error("Error: sessionId is undefined or null");
        return;
      }
  
      let checkoutOptions = {
        paymentSessionId: sessionId,
        redirectTarget: "_modal", // "_self" for same page, "_blank" for new tab
      };
  
      console.log("Initializing checkout with options:", checkoutOptions);
      if (!cashfreeRef.current) {
        console.error("Cashfree SDK not initialized.");
        alert("Cashfree SDK not initialized. Please refresh and try again.");
        return;
      }

      cashfreeRef.current.checkout(checkoutOptions).then(() => {
        console.log("Payment initialized successfully");
        verifyPayment(profileId,orderId);
      }).catch((checkoutError) => {
        console.error("Error initializing checkout:", checkoutError);
      });
    } catch (error) {
      console.error("Checkout Error:", error.message);
    } finally {
      console.log("handlePurchase execution completed");
      setLoading(false);
    }
  };
  
  
  const goalPrices = {
    SSC: 50,
    RRB: 20,
    BANKING: 30,
  };
  
  
  return (
    <>
      <Navbar />
      <div className="px-[4rem]">
        <div className="w-full mt-4 max-w-5xl">
          {isLoadingProfiles ? (
            <p>Loading profiles...</p>
          ) : profiles.length === 0 ? (
            <p>No mutual transfer profiles found.</p>
          ) : (
            profiles.map((profile) => (
              <div key={profile._id}>
                <div className="form mb-5 text-xl font-semibold">Your transfer profile</div>
                <div className="bg-[#EDEDED] shadow rounded-lg p-6 mb-8">
                  <div className="flex items-center gap-2 justify-end">
                    <img src={editIcon} alt="edit Icon" />
                    <button
                      className="form text-sm text-[#364374] hover:underline flex items-center"
                      onClick={() => handleEditClick(profile)}
                    >
                      Edit
                    </button>
                    <>|</>
                    <label className="form text-sm text-green-700 flex items-center cursor-pointer">
                    <div className="relative inline-block">

                    {isHovered && (
        <div className="absolute top-10 left-1/2 -translate-x-1/2 w-56 px-3 py-2 bg-black text-white text-xs rounded-md shadow-md transition-opacity duration-300">
          {profile.isProfileVisible
            ? "Toggle off when you no longer need a mutual transfer match."
            : "Your contact details will not be visible to others."}
        </div>
      )}
      <Switch
        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-all duration-300 ${
          profile.isProfileVisible ? "bg-green-500" : "bg-gray-300"
        }`}
        checked={profile.isProfileVisible}
        onCheckedChange={() => toggleProfileVisibility(profile._id, profile.isProfileVisible)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span
          className={`absolute left-1 top-1 h-4 w-4 transform rounded-full bg-white transition ${
            profile.isProfileVisible ? "translate-x-5" : "translate-x-0"
          }`}
        />
      </Switch>
      </div>

                  {/* <span className="ml-2">{profile.isProfileVisible ? "Visible" : "Hidden"}</span> */}
                </label>
                  </div>
                  <div className="form font-semibold text-gray-700">
                    <p>Name: {profile.fullName}</p>
                    <p>Post: {profile.post}</p>
                                      {/* <p>Selected Mutual Transfer: {profile.selectedGoal}</p> */}
                    <p>Category: {profile.category}</p>
                    <p>
  Current posting: 
  {profile.currentZone ? ` ${profile.currentZone}` : " "} 
  {profile.currentDivision ? ` | ${profile.currentDivision} ` : " "}
  {profile.currentPosting?.length
    ? `${profile.currentZone || profile.currentDivision ? " | " : ""}${profile.currentPosting.join(" / ")}`
    : "Not Available"}
</p>
<p>
  Desired posting: 
  {profile.desiredZone ? ` ${profile.desiredZone}` : " "}
  {profile.desiredDivision ? ` | ${profile.desiredDivision} ` : " "}
  {profile.desiredPosting?.length
    ? `${profile.desiredZone || profile.desiredDivision ? " | " : ""}${profile.desiredPosting.join(" / ")}`
    : "Not Available"}
   {/* | {profile.desiredPosting?.length ? profile.desiredPosting.join(" / ") : "Not Available"} */}
</p>

                  </div>
                </div>

                {/* Matching results */}
                <div>
                  <h3 className="form text-xl font-semibold mb-4">Matching results</h3>
                  {isLoadingMatches ? (
                    <p>Loading matches...</p>
                  ) : (
                    <div>
                      {matchingResults.filter((result) => result.selectedGoal === profile.selectedGoal).length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                          {matchingResults
                            .filter((result) => result.selectedGoal === profile.selectedGoal)
                            .map((result, index) => (
                              <div
                                key={index}
                                className="bg-[#FEDDAB] form p-5 px-10 font-semibold text-sm rounded-lg shadow"
                              >
                                <p>Name: {result.fullName}</p>
                                <p>Category: {result.category}</p>
                                {/* <p>Current posting: {result.currentPosting},{profile.currentZone},{profile.currentDivision}</p> */}
                                <p>
  Current posting: 
  {profile.currentZone ? ` ${profile.currentZone}` : " "}
  {profile.currentDivision ? ` | ${profile.currentDivision} ` : " "}
  {profile.currentPosting?.length
    ? `${profile.currentZone || profile.currentDivision ? " | " : ""}${profile.currentPosting.join(" / ")}`
    : "Not Available"}
  {/* | {profile.currentPosting?.length ? profile.currentPosting.join(" / ") : "Not Available"} */}
</p>
                                <p>Mobile Number: {result.mobileNumber}</p>
                                <p>Email: {result.email}</p>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p className = "mt-2 mb-6">No matches found.</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>

       
{/* Edit Confirmation Modal */}
{isEditModalVisible && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
      {/* Close Button */}
      <button
        onClick={() => handleCloseModal()}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
      >
        ✖
      </button>

      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {isEditing ? "Processing..." : "Modifying your submission will incur a nominal charge."}
      </h2>

      {!isEditing ? (
        <>
          <p className="text-sm text-gray-700 mb-6">Do you wish to proceed?</p>
          <div className="flex justify-end items-center gap-4">
            <p className="font-bold text-lg">₹{goalPrices[selectedProfile.selectedGoal] || 10}</p>
            <button
              className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded"
              onClick={() => handlePayment(selectedProfile._id, selectedProfile.selectedGoal,goalPrices[selectedProfile.selectedGoal] )}
            >
              Pay Now
            </button>
          </div>
        </>
      ) : (
        <div className="text-center text-gray-600">
          <p>Loading...</p>
        </div>
      )}
    </div>
  </div>
)}


      </div>
    </>
  );
};

export default TransferProfile;
